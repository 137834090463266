import React from "react";

const coachperformancecolumns = () => [
  {
    Header: "Essay Coach",
    accessor: "employee_name",
    Cell: ({ row }) => row.values.employee_name || "-",
  },
  {
    Header: "Students Assigned",
    accessor: "total_students",
    Cell: ({ row }) => row.values.total_students || "-",
  },
  {
    Header: "Used Hours/Total Hours",
    accessor: "total_hours",
    Cell: ({ row }) =>
      row.values.total_hours !== null && row.values.used_hours !== null
        ? row.values.used_hours + "/" + row.values.total_hours
        : "-",
  },
  {
    Header: "Remaining Hours",
    accessor: "used_hours",
    Cell: ({ row }) =>
      !isNaN(
        parseFloat(row.values.total_hours) - parseFloat(row.values.used_hours)
      )
        ? (
            parseFloat(row.values.total_hours) -
            parseFloat(row.values.used_hours)
          ).toFixed(2)
        : "-",
  },
  {
    Header: "Next Scheduled Meeting",
    accessor: "total_students_with_next_meeting",
    Cell: ({ row }) =>
      row.values.total_students && row.values.total_students_with_next_meeting
        ? Math.round(
            (parseFloat(row.values.total_students_with_next_meeting) * 100) /
              parseFloat(row.values.total_students)
          ) + "%"
        : "-",
  },
  {
    Header: "Hrs Booked Last 7 Days",
    accessor: "used_hours_last_week",
    Cell: ({ row }) => row.values.used_hours_last_week || "-",
  },
  {
    Header: "Meetings Last 7 Days",
    accessor: "total_meetings_last_week",
    Cell: ({ row }) => row.values.total_meetings_last_week || "-",
  },
  {
    Header: "Meetings in Last 30 Days",
    accessor: "total_meetings_per_week",
    Cell: ({ row }) => row.values.total_meetings_per_week || "-",
  },
  {
    Header: "Common App Personal Statement Workshop",
    accessor: "avg_common_app_personal_statement_workshop",
    Cell: ({ row }) =>
      !isNaN(parseFloat(row.values.avg_common_app_personal_statement_workshop))
        ? Math.round(
            parseFloat(row.values.avg_common_app_personal_statement_workshop) *
              100
          ).toString() + "%"
        : "-",
  },
  {
    Header: "Common App Personal Statement Topic Identified",
    accessor: "avg_common_app_personal_statement_topic_identified",
    Cell: ({ row }) =>
      !isNaN(
        parseFloat(
          row.values.avg_common_app_personal_statement_topic_identified
        )
      )
        ? Math.round(
            parseFloat(
              row.values.avg_common_app_personal_statement_topic_identified
            ) * 100
          ).toString() + "%"
        : "-",
  },
  {
    Header: "Complete My Common App Personal Statement",
    accessor: "avg_complete_my_common_app_personal_statement",
    Cell: ({ row }) =>
      !isNaN(
        parseFloat(row.values.avg_complete_my_common_app_personal_statement)
      )
        ? Math.round(
            parseFloat(
              row.values.avg_complete_my_common_app_personal_statement
            ) * 100
          ).toString() + "%"
        : "-",
  },
  {
    Header: "Supplemental Essay Workshop",
    accessor: "avg_supplemental_essay_workshop",
    Cell: ({ row }) =>
      !isNaN(parseFloat(row.values.avg_supplemental_essay_workshop))
        ? Math.round(
            parseFloat(row.values.avg_supplemental_essay_workshop) * 100
          ).toString() + "%"
        : "-",
  },
  {
    Header: "Supplemental Essay Strategy",
    accessor: "avg_supplemental_essay_strategy",
    Cell: ({ row }) =>
      !isNaN(parseFloat(row.values.avg_supplemental_essay_strategy))
        ? Math.round(
            parseFloat(row.values.avg_supplemental_essay_strategy) * 100
          ).toString() + "%"
        : "-",
  },
  {
    Header: "All Essays Reviewed",
    accessor: "avg_all_essays_reviewed",
    Cell: ({ row }) =>
      !isNaN(parseFloat(row.values.avg_all_essays_reviewed))
        ? Math.round(
            parseFloat(row.values.avg_all_essays_reviewed) * 100
          ).toString() + "%"
        : "-",
  },
];

export { coachperformancecolumns };
