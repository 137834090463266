import React from "react";

const counselorperformancecolumns = () => [
  {
    Header: "Counselor",
    accessor: "employee_name",
    Cell: ({ row }) => row.values.employee_name || "-",
  },
  {
    Header: "Students Assigned",
    accessor: "total_select_students",
    Cell: ({ row }) => row.values.total_students || "-",
  },
  {
    Header: "Select Students",
    accessor: "total_students",
    Cell: ({ row }) => row.values.total_select_students || "-",
  },
  {
    Header: "Premier Students",
    accessor: "total_premier_students",
    Cell: ({ row }) => row.values.total_premier_students || "-",
  },
  {
    Header: "Elite Students",
    accessor: "total_elite_students",
    Cell: ({ row }) => row.values.total_elite_students || "-",
  },
  {
    Header: "2029",
    accessor: "total_yog_plus_5_students",
    Cell: ({ row }) => row.values.total_yog_plus_5_students || "-",
  },
  {
    Header: "2028",
    accessor: "total_yog_plus_4_students",
    Cell: ({ row }) => row.values.total_yog_plus_4_students || "-",
  },
  {
    Header: "2027",
    accessor: "total_yog_plus_3_students",
    Cell: ({ row }) => row.values.total_yog_plus_3_students || "-",
  },
  {
    Header: "2026",
    accessor: "total_yog_plus_2_students",
    Cell: ({ row }) => row.values.total_yog_plus_2_students || "-",
  },
  {
    Header: "2025",
    accessor: "total_yog_plus_1_students",
    Cell: ({ row }) => row.values.total_yog_plus_1_students || "-",
  },
  {
    Header: "Other YOG",
    accessor: "total_yog_plus_other_students",
    Cell: ({ row }) => row.values.total_yog_plus_other_students || "-",
  },
  {
    Header: "Meetings Used/Meetings Total",
    accessor: "total_meetings",
    Cell: ({ row }) =>
      row.values.used_meetings !== null && row.values.total_meetings !== null
        ? row.values.used_meetings + "/" + row.values.total_meetings
        : "-",
  },
  {
    Header: "Meetings Remaining",
    accessor: "used_meetings",
    Cell: ({ row }) =>
      !isNaN(row.values.total_meetings - row.values.used_meetings) &&
      row.values.total_meetings - row.values.used_meetings >= 0
        ? row.values.total_meetings - row.values.used_meetings
        : !isNaN(row.values.total_meetings - row.values.used_meetings) &&
          row.values.total_meetings - row.values.used_meetings < 0
        ? 0
        : "-",
  },
  {
    Header: "Next Meeting Scheduled",
    accessor: "total_students_with_next_meeting",
    Cell: ({ row }) => {
      const {
        total_students_with_next_meeting,
        total_yog_plus_5_students,
        total_yog_plus_4_students,
        total_yog_plus_3_students,
        total_yog_plus_2_students,
        total_yog_plus_other_students
      } = row.values;

      const totalStudents = 
        total_yog_plus_5_students + 
        total_yog_plus_4_students + 
        total_yog_plus_3_students + 
        total_yog_plus_2_students + 
        total_yog_plus_other_students;

      if (totalStudents && total_students_with_next_meeting) {
        const percentage = (parseFloat(total_students_with_next_meeting) * 100) / parseFloat(totalStudents);
        return Math.round(percentage) + "%";
      }
  
      return "-";
    }
  },  
  {
    Header: "Meetings Last 7 Days",
    accessor: "total_meetings_last_week",
    Cell: ({ row }) => row.values.total_meetings_last_week || "-",
  },
  {
    Header: "Meetings in Past 30 Days",
    accessor: "total_meetings_last_month",
    Cell: ({ row }) => row.values.total_meetings_last_month || "-",
  },
  {
    Header: "Meetings Next 7 Days",
    accessor: "total_students_with_meeting_this_week",
    Cell: ({ row }) => row.values.total_students_with_meeting_this_week || "-",
  },
  {
    Header: "Meetings in Next 30 Days",
    accessor: "total_students_with_meeting_next_month",
    Cell: ({ row }) => row.values.total_students_with_meeting_next_month || "-",
  },
];

export { counselorperformancecolumns };
